<template>
  <section class="select-present-cards">
    <div class="header-product">
      <div class="slider-product" v-if="receiveSelectGift !== null">
        <img :src="receiveSelectGift.image.url" alt="">
      </div>
      <div class="product-description">
        <h2 class="title" v-if="receiveSelectGift !== null">{{ receiveSelectGift.title }}</h2>
        <div class="price-product">
          <h4 class="price" v-if="receiveSelectGift !== null">€ {{ receiveSelectGift.price/100 }}</h4>
        </div>
        <div class="text">
          <textarea v-model="text" placeholder="Enter your message here..." name="" id="" cols="30" rows="10"></textarea>
          <p >Max 120 characters</p>
        </div>
        <div class="container">
          <div class="container-for-button">
            <div class="btn-add-to-card" @click="addToBlank">
              <img src="@/assets/img/SelectProduct/Cart.svg" alt="">
              <h6>Add to cart</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "SelectPresentCards",
  props:{
    openBasket:{
      type:Function
    }
  },
  data(){
    return{
      text:''
    }
  },
  methods:{
    addToBlank(){
      this.$store.dispatch('changeProductDataCard',{...this.receiveSelectGift,text:this.text});
      // this.openBasket();
    }
  },
  watch:{
    '$route.params.cardId':{
      handler(cardId){
        this.$store.dispatch('getSelectGift',cardId)
      },
      deep:true,
      immediate:true,
    },
    '$route.params.productId':{
      handler(productId){
        this.$store.dispatch('getSelectProduct',productId)
      },
      deep:true,
      immediate:true,
    }
  },
  computed:{
    receiveSelectGift() {
      return this.$store.getters['receiveSelectGift']
    }
  }
}
</script>

<style scoped lang="scss">
@import "SelectPresentCards";
</style>